<template>
  <header class="main-header">
    <cookie-law position="top" transitionName="slideFromTop" v-on:accept="cookieAccepted()">
      <div slot="message">
        By continuing to use our website, you consent to the use of cookies. Please refer our
        <a
          class="cookies_link"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.grazitti.com/cookie-policy/"
        >cookie policy</a> for more details.
      </div>
    </cookie-law>
    <nav id="site-navbar" class="navbar navbar-expand-lg fixed-top site-navbar site-fixed-navbar">
      <div class="container">
        <a class="navbar-brand" href="https://www.grazitti.com/">
          <img class="img-fluid img-colored logo-h" src="/wp-content/uploads/2021/01/Grazitticolored-logo.svg" alt="Logo" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          v-bind:class="[isActive ? 'sidebarOpen' : '']"
          @click="isActive = !isActive"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/portfolio">Portfolio</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/services">Services</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/faq">FAQ</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/about">About Us</router-link>
            </li>
            <li class="nav-item get-quote">
              <router-link class="nav-link" to="/get-a-quote">Get a Quote</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import CookieLaw from "vue-cookie-law";
export default {
  data() {
    return {
      isActive: false
    };
  },
  components: {
    CookieLaw
  },
  methods: {
    cookieAccepted: function() {
      document.querySelector("body").classList.remove("cookie-not-set");
      document.querySelector("body").classList.add("cookie-set");
    }
  }
};
</script> 
